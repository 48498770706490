// FramebusListener.js
import React, { useEffect, useRef } from 'react';
import Framebus from 'framebus';

const FramebusListener = ({ onMessage, appId, message }) => {
  let busRef = useRef()


  useEffect(() => {
    let bus = new Framebus();
    busRef.current = bus
    console.log('sending init message from 3d model');
    bus.emit('message', {action: "init" }, function (reply) {
      console.log('Reply from listener:', reply);
    });
    // This function will be called when a message is received from the parent


    // Subscribe to the "parentMessage" event
    console.log('listening framebus on 3d model');
    bus.on('message', handleMessage);

    // Clean up the subscription when the component unmounts
    return () => {
      bus.off('message', handleMessage);
    };
  }, []);
  useEffect(() => {
    if (busRef.current && message){
      console.log('sending message from 3d model', message);
      busRef.current.emit('message', {
        action: message.action,
        data: message.data

      })
    }

  }, [message])
  useEffect(() => {
    if (busRef.current && appId){
      console.log('sending message from 3d model', appId);
      busRef.current.emit('message', {
        action: 'open-apartment',
        apartmentId: appId, // apartmentId or apartmentTitle
        apartmentTitle: appId
      })
    }

  }, [appId])
  

  const handleMessage = (data, reply) => {
    // console.log("handleMessage", data)
    if (onMessage) {
      onMessage(data, reply);
    }
  };
  return null;
};

export default FramebusListener;
