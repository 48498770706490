import gsap from "gsap";
import { DataTexture, LinearFilter, Quaternion, RepeatWrapping, RGBAFormat, Sprite, UnsignedByteType,  } from "three";
import { MeshBasicMaterial } from "three";
import { Mesh } from "three";
import { Texture } from "three";
import { DoubleSide } from "three";
import { CircleGeometry } from "three";
import { SpriteMaterial } from "three";
import { CanvasTexture } from "three";

export function createLabel(text) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set canvas size
  canvas.width = 256;
  canvas.height = 128;

  // Set the background to be transparent
  context.clearRect(0, 0, canvas.width, canvas.height);

  // Set text properties
  context.font = "40px Arial";
  context.fillStyle = "white";
  context.textAlign = "center";
  context.textBaseline = "middle";

  // Draw the text
  context.fillText(text, canvas.width / 2, canvas.height / 2);

  // Create texture from canvas
  const texture = new CanvasTexture(canvas);
  const spriteMaterial = new SpriteMaterial({ 
      map: texture, 
      transparent: true,
      depthTest: false // Disable depth testing
  });
  const sprite = new Sprite(spriteMaterial);

  // Set sprite scale
  sprite.scale.set(50, 25, 1); // Adjust scale as needed

  // Adjust rendering order
  sprite.renderOrder = 999;

  return sprite;
}

 export const createGradientCircle = (
    radius,
    x,
    y,
    z,
    colorSteps,
    visible = false
  ) => {
    const canvas = document.createElement("canvas");
    canvas.width = 128;
    canvas.height = 128;

    const context = canvas.getContext("2d");
    const gradient = context.createRadialGradient(
      canvas.width / 2,
      canvas.height / 2,
      0,
      canvas.width / 2,
      canvas.height / 2,
      canvas.width / 2
    );
    colorSteps.forEach((step) =>
      gradient.addColorStop(step.position, step.color)
    );
    context.fillStyle = gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);

    let texture = new Texture(canvas);
    texture.needsUpdate = true;

    let geometry = new CircleGeometry(radius, 32);
    const material = new MeshBasicMaterial({
      map: texture,
      side: DoubleSide,
    });

    const circle = new Mesh(geometry, material);
    circle.rotation.x = -Math.PI / 2;
    circle.position.set(x, y, z);
    // console.log(circle);
    circle.visible = visible;
    
    circle.updateRadius = (offSet) => {
      circle.scale.set(1 + offSet / 10, 1 + offSet / 10, 1 + offSet / 10);
    };
    return circle;
  };

  export const handleCircleSize = (offset, circle) => {
    // console.log("mainCircle.current", mainCircle.current)
    // mainCircleRadiusOffset.current = offset;
    circle.rasiusOffset = offset;
    circle.updateRadius(offset);
  };
  
  export function animateCamera(camera, targetPosition, targetQuaternion, duration, easeName = "power4.out") {
    return new Promise((resolve) => {
      const animations = [];
  
      if (targetPosition) {
        animations.push(gsap.to(camera.position, {
          duration: duration,
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          ease: easeName,
          onUpdate: () => {
            // camera.updateProjectionMatrix();
          },
        }));
      }
  
      if (targetQuaternion) {
        
        animations.push(gsap.to(camera.quaternion, {
          duration: duration,
          x: targetQuaternion.x,
          y: targetQuaternion.y,
          z: targetQuaternion.z,
          w: targetQuaternion.w,
          ease: easeName,
          onUpdate: () => {
            // camera.updateProjectionMatrix();
          },
        }));
      }
  
      gsap.timeline({ onComplete: resolve }).add(animations);
    });
  }
  export function animateCameraPosition(
    camera,
    targetPosition,
    duration,
    easeName = "power4.out"
  ) {
    if (targetPosition) {
      return new Promise((resolve) => {
        gsap.to(camera.position, {
          duration: duration,
          x: targetPosition.x,
          y: targetPosition.y,
          z: targetPosition.z,
          ease: easeName, // or any other easing function
          onUpdate: () => {
            // camera.updateProjectionMatrix();
          }, // required when the camera position is changing
          onComplete: resolve,
        });
      });
    }
  }
  export function animateCameraRotation(
    camera,
    targetRotation,
    duration,
    easeName = "power4.out"
  ) {
    if (targetRotation) {
      const targetQuaternion = new Quaternion().setFromEuler(targetRotation); 
      return new Promise((resolve) => {
        gsap.to(camera.quaternion, {
          duration: duration,
          x: targetQuaternion.x,
          y: targetQuaternion.y,
          z: targetQuaternion.z,
          w: targetQuaternion.w,
          ease: easeName, // or any other easing function
          onUpdate: () => {
            // camera.updateProjectionMatrix();
          }, // required when the camera position is changing
          onComplete: resolve,
        });
      });
    }
  }

  export function createSunPosition(elevation, azimuth) {
    const phi = (90 - elevation) * (Math.PI / 180); // Convert to radians
    const theta = azimuth * (Math.PI / 180); // Convert to radians
  
    const x = Math.sin(phi) * Math.cos(theta);
    const y = Math.cos(phi);
    const z = Math.sin(phi) * Math.sin(theta);
  
    return { x, y, z };
  }

  export function generateRadialFloorTexture(dim, color = { r: 134, g: 123, b: 145 }) {
    const data = new Uint8Array(dim * dim * 4);

    for (let x = 0; x < dim; x++) {
      for (let y = 0; y < dim; y++) {
        const xNorm = x / (dim - 1);
        const yNorm = y / (dim - 1);

        const xCent = 2.0 * (xNorm - 0.5);
        const yCent = 2.0 * (yNorm - 0.5);
        let a = Math.max(
          Math.min(1.0 - Math.sqrt(xCent ** 2 + yCent ** 2), 1.0),
          0.0
        );
        a = a ** 1.5;
        a = a * 1.5;
        a = Math.min(a, 1.0);

        const i = y * dim + x;
        data[i * 4 + 0] = color.r; // Red component
        data[i * 4 + 1] = color.g; // Green component
        data[i * 4 + 2] = color.b; // Blue component
        data[i * 4 + 3] = a * 255; // Alpha component
      }
    }

    const tex = new DataTexture(data, dim, dim);
    tex.format = RGBAFormat;
    tex.type = UnsignedByteType;
    tex.minFilter = LinearFilter;
    tex.magFilter = LinearFilter;
    tex.wrapS = RepeatWrapping;
    tex.wrapT = RepeatWrapping;
    tex.needsUpdate = true;
    return tex;
  }

  export const createCircle = (radius, x, y, z) => {
    const geometry = new CircleGeometry(radius, 32); // CircleGeometry(radius, segments), adjust radius as needed

    const material = new MeshBasicMaterial({
      color: 0x800080, // Hex color for purple
      side: DoubleSide,
    });

    const circle = new Mesh(geometry, material);
    circle.rotation.x = -Math.PI / 2; // Rotate the circle to be parallel to the ground
    // circle.position = position;
    circle.position.set(x, y, z); // Set the position here

    return circle;
  };