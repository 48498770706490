import React from 'react'
import CheckBox from './CheckBox';

const Input = ({ label, type, value, onChange, step }) => {
    switch (type) {
        case 'checkbox':
            return <CheckBox label={label} value={value} onChange={onChange}/>
    
        default:
            return (
                <div className="d-flex align-items-center justify-between">
                  <label
                    style={{
                      width: "125px",
                      textAlign: "left",
                    }}
                    htmlFor={label}
                  >
                    {label}
                  </label>
                  <input
                    style={{
                      width: "50px",
                      textAlign: "center",
                    }}
                    name={label}
                    type={type}
                    step={step ? step : 1}
                    defaultValue={value}
                    // onBlur={(e) =>
                    //   onChange({
                    //     label,
                    //     value: e.target.value,
                    //   })
                    // }
                    onChange={(e) =>  onChange(e.target.value)
                    }
                  />
                </div>
              );;
    }

  };

export default Input