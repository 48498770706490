import React from 'react';

function Dot(props) {
    const { cx, cy, radius, fill, style } = props;
    
    return (
        <svg style={style}>
            <circle cx={cx} cy={cy} r={radius} fill={fill} />
        </svg>
    );
}

export default Dot;