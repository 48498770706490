

import "./App.css";
import { AppData } from "./AppData";
import Scene from "./ThreeScene";

function App() {
  
  return (
    <div className="App" id="map">
      <AppData />

    </div>
  )
}

export default App;
