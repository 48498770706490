import React, { useEffect } from "react";
import {
  AdditiveBlending,
  BufferAttribute,
  BufferGeometry,
  CanvasTexture,
  IcosahedronGeometry,
  Points,
  PointsMaterial,
  Vector3,
} from "three";

const createStarTexture = () => {
  const canvas = document.createElement("CANVAS");
  canvas.width = 128;
  canvas.height = 128;

  const context = canvas.getContext("2d");
  context.globalAlpha = 0.3;
  context.filter = "blur(16px)";
  context.fillStyle = "white";
  context.beginPath();
  context.arc(64, 64, 40, 0, 2 * Math.PI);
  context.fill();
  context.globalAlpha = 1;
  context.filter = "blur(5px)";
  context.fillStyle = "white";
  context.beginPath();
  context.arc(64, 64, 16, 0, 2 * Math.PI);
  context.fill();

  return new CanvasTexture(canvas);
};

const createStars = (scene, texture) => {
  const N = 200;
  const positions = [];
  const colors = [];
  let v = new Vector3();

  for (let i = 0; i < N; i++) {
    v.randomDirection().setLength(3 + 2 * Math.pow(Math.random(), 1 / 3));
    // if (v.y > 0) { // Ensure star is in the sky
        // console.log("v", v);
      positions.push(v.x, v.y, v.z);
      colors.push(Math.random(), Math.random(), Math.random());
    // }
  }

  const positionAttribute = new BufferAttribute(new Float32Array(positions), 3);
  const colorAttribute = new BufferAttribute(new Float32Array(colors), 3);

  const geometry = new BufferGeometry();
  geometry.setAttribute("position", positionAttribute);
  geometry.setAttribute("color", colorAttribute);

  const material = new PointsMaterial({
    color: "white",
    vertexColors: true,
    size: 2,
    sizeAttenuation: true,
    map: texture,
    transparent: true,
    blending: AdditiveBlending,
    depthWrite: false,
  });

  const cloud = new Points(geometry, material);
  // scene.add(cloud);

  const starsMaterial = new PointsMaterial({
    size: 1 / 6,
    sizeAttenuation: true,
    map: texture,
    transparent: true,
    opacity: 1
  });

  for (let i = 0; i < 10; i++) {
    const dome = new Points(new IcosahedronGeometry(1000, 4), starsMaterial);
    dome.rotation.set(6 * Math.random(), 6 * Math.random(), 6 * Math.random());
    scene.add(dome);
  }
};

const Stars = ({ scene, show }) => {
  useEffect(() => {
    const texture = createStarTexture();
    createStars(scene, texture);
  }, [scene]);
  useEffect(() => {
    if (show) {
      scene.children.forEach((child) => {
        if (child.type === "Points") {
          child.material.opacity = 1;
        }
      });
    } else {
      scene.children.forEach((child) => {
        if (child.type === "Points") {
          child.material.opacity = 0;
        }
      });
    }
  }, [show]);

  return null;
};

export default Stars;