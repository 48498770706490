import React, { useState, useEffect, useRef } from "react";
import { ARROWS_DISTANCE, CIRCLE_SIZE, LINE_COLOR, LINE_START, LINE_WIDTH, MAX_DISTANCE, PODIUM_SCALE, RESIZE_MODEL, ROTATE_FLOORS, ROTATE_MODEL, SHOW_INITIAL_ANIMATION, TRANSPARENCY, X, Z } from "../constants";
import Input from "./Settings/Input";

export const initialLineWidth = 30;
export const defaultSettings = {
  Project: [
    {
      label: ARROWS_DISTANCE,
      type: "number",
      value: 3,
      property: "arrowsRadiusOffset",
    },
    // {
    //   label: CIRCLE_SIZE,
    //   type: "number",
    //   value: 1,
    //   step: 0.1,
    //   property: "mainCircleRadiusOffset",
    // },
    {
      label: PODIUM_SCALE,
      type: "number",
      value: 1,
      step: 0.1,
      property: "podiumScale",
    },
    {
      label: MAX_DISTANCE,
      type: "number",
      value: 350,
      property: "maxDistance",
    },
    {
      label: TRANSPARENCY,
      type: "number",
      value: 0.3,
      step: 0.1,
      property: "transparency",
    },
    {
      label: SHOW_INITIAL_ANIMATION,
      type: "checkbox",
      value: true,
      property: "showInitialAnimation",
    },

  ],
  // console.log({ selectedBuilding })
  Buildings: [
    {
      label: X,
      type: "number",
      value: 0,
      property: "x",
      step: 0.1,
    },
    {
      label: Z,
      type: "number",
      value: 0,
      property: "z",
      step: 0.1,
    },
    {
      label: RESIZE_MODEL,
      type: "number",
      value: 1,
      property: "scale",
      step: 0.1,
    },
    {
      label: ROTATE_MODEL,
      type: "number",
      value: 0,
      property: "rotationY",
      step: 0.1,
    },
    {
      label: ROTATE_FLOORS,
      type: "number",
      value: 0,
      property: "floorsRotationY",
      step: 0.1,
    },
    {
      label: CIRCLE_SIZE,
      type: "number",
      value: 3,
      property: "buildingRadiusOffset",
    },
  ],
  Tiles: [
    {
      label: X,
      type: "number",
      value: 0,
      property: "x",
      step: 0.1,
    },
    {
      label: Z,
      type: "number",
      value: 0,
      property: "z",
      step: 0.1,
    },
    {
      label: RESIZE_MODEL,
      type: "number",
      value: 1,
      property: "scale",
      step: 0.1,
    },
    {
      label: ROTATE_MODEL + "X",
      type: "number",
      value: 0,
      property: "rotationX",
      step: 0.1,
    },
    {
      label: ROTATE_MODEL + "Y",
      type: "number",
      value: 0,
      property: "rotationY",
      step: 0.1,
    },
    {
      label: ROTATE_MODEL + "Z",
      type: "number",
      value: 0,
      property: "rotationZ",
      step: 0.1,
    },
  ],
  Floors: [
    {
      label: LINE_COLOR,
      type: "color",
      value: "#ff0000",
      property: "floorLineColor",
      step: 0.1,
    },
    {
      label: LINE_START,
      type: "number",
      value: 0,
      property: "lineStart",
      step: 1,
    },
    {
      label: LINE_WIDTH,
      type: "number",
      value: initialLineWidth,
      property: "lineWidth",
      step: 1,
    },
    

    // {
    //   label: "Label size",
    //   type: "number",
    //   value: 3,
    //   property: "floorsLabelsSize",
    // },
  ]
}
const Settings = ({
  projectDefaultView,
  buildings,
  saveStatus,
  onChange,
  saveProject,
  saveViewData,
  setSelectedBuildingName,
  selectedBuildingName,
  
}) => {
    // console.log("projectDefaultView", projectDefaultView);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [form, setForm] = useState(defaultSettings)
  const updatedSettings = useRef(defaultSettings);
  const selectedBuilding = buildings.find(
    (building) => building.name === selectedBuildingName
  );
  useEffect(() => {
    const newForm = { ...form };
    // console.log({projectDefaultView})
    if (projectDefaultView) {
      if (projectDefaultView.arrowsRadiusOffset)
      newForm.Project.find((setting) => setting.label === ARROWS_DISTANCE).value = projectDefaultView.arrowsRadiusOffset
      if (projectDefaultView.showInitialAnimation)
      newForm.Project.find((setting) => setting.label === ARROWS_DISTANCE).value = projectDefaultView.showInitialAnimation
      if (projectDefaultView.podiumScale)
      newForm.Project.find((setting) => setting.label === PODIUM_SCALE).value = projectDefaultView.podiumScale
      // if (projectDefaultView.mainCircleRadiusOffset)
      // newForm.Project.find((setting) => setting.label === CIRCLE_SIZE).value = projectDefaultView.mainCircleRadiusOffset
      if (projectDefaultView.maxDistance)
      newForm.Project.find((setting) => setting.label === MAX_DISTANCE).value = projectDefaultView.maxDistance
      if (projectDefaultView.transparency)
        newForm.Project.find((setting) => setting.label === TRANSPARENCY).value = projectDefaultView.transparency
      if (projectDefaultView.floorLineColor)
        newForm.Floors.find((setting) => setting.label === LINE_COLOR).value = projectDefaultView.floorLineColor
      if (projectDefaultView.lineStart)
        newForm.Floors.find((setting) => setting.label === LINE_START).value = projectDefaultView.lineStart
      if (projectDefaultView.lineWidth)
        newForm.Floors.find((setting) => setting.label === LINE_WIDTH).value = projectDefaultView.lineWidth
    }
    // console.log({selectedBuilding})
    if (selectedBuilding && selectedBuilding.default_view) {
      if (selectedBuilding.default_view.position){
        if (selectedBuilding.default_view.position.x)
        newForm.Buildings.find((setting) => setting.label === X).value = selectedBuilding.default_view.position.x
        if (selectedBuilding.default_view.position.z)
        newForm.Buildings.find((setting) => setting.label === Z).value = selectedBuilding.default_view.position.z

      }
      if (selectedBuilding.default_view.scale)
      newForm.Buildings.find((setting) => setting.label === RESIZE_MODEL).value = selectedBuilding.default_view.scale.x
      if (selectedBuilding.default_view.rotation && selectedBuilding.default_view.rotation._y)
      newForm.Buildings.find((setting) => setting.label === ROTATE_MODEL).value = selectedBuilding.default_view.rotation._y
      if (selectedBuilding.default_view.circleRadiusOffset)
      newForm.Buildings.find((setting) => setting.label === CIRCLE_SIZE).value = selectedBuilding.default_view.circleRadiusOffset
    }
    setForm(newForm);
    updatedSettings.current = newForm;
  
    return () => {
      
    }
  }, [selectedBuildingName, buildings, projectDefaultView])
  
  const tabs = Object.keys(form);
  const onTabChange = (index, tabName) => {
    // console.log({menuItem})
    // console.log({index, tabName});
    // dispatch(setSelectedMenuItem(menuItem));
    if (activeTabIndex !== index) {
      setActiveTabIndex(index);
    }
  };
  const handleOnChange = (e, tab) => {
    const { label, value } = e;
    console.log({ label, value, tab })
    const newForm = { ...updatedSettings.current };
    newForm[tab].find((setting) => setting.label === label).value = value;
    // setForm(newForm);
    updatedSettings.current = newForm;
    onChange(e, tab);
  }
  const BuildingsSettings = ({
    settings,
    selectedBuildingName
  }) => {
    function handleBuildingSelectChange(event) {
      // console.log(buildings)
      setSelectedBuildingName(event.target.value);
    }
    const selectedBuilding = buildings.find(
      (building) => building.name === selectedBuildingName
    );
    // console.log({ selectedBuildingName });
    return (
      <div>
        <select value={selectedBuildingName} onChange={handleBuildingSelectChange}>
        <option value={null}>
          Select building
              </option>
          {buildings.map((building, i) => {
            return (
              <option key={i} value={building.name}>
                {building.name}
              </option>
            );
          })}
        </select>
        {/* <button onClick={() => saveViewData("building")}>
          Save building view
        </button> */}
        {selectedBuilding && settings.map((setting, i) => {
          return <Input key={i} {...setting} onChange={(e) => handleOnChange(e, "Buildings")} />;
        })}
        {selectedBuilding && <button
          className={saveStatus == "Save" ? "bgGreen" : "bgRed disabled"}
          onClick={() => saveViewData("building-floors")}
        >
          Save floors view
        </button>}
        {selectedBuilding && <button
          className={saveStatus == "Save" ? "bgGreen" : "bgRed disabled"}
          onClick={() => saveViewData("building")}
        >
          {saveStatus}
        </button>}
        {/* <button onClick={() => saveViewData("building")}>
          Reset
        </button> */}
      </div>
    );
  };

  const FloorsSettings = () => {

    // console.log({ selectedBuildingName });
    return (
      <div>

        {form.Floors.map((setting, i) => {
          return <Input key={i} {...setting} onChange={(e) => handleOnChange(e, "Floors")} />;
        })}
        <button
          className={saveStatus == "Save" ? "bgGreen" : "bgRed disabled"}
          onClick={() => saveProject(updatedSettings.current)}
        >
          {saveStatus}
        </button>
        {/* <button onClick={() => saveViewData("building")}>
          Reset
        </button> */}
      </div>
    );
  };
  const ProjectSettings = () => {
    return (
      <div>
        <button onClick={() => saveViewData("project")}>
          Save project view
        </button>
        {form.Project.map((setting, i) => {
          return <Input key={i} {...setting} onChange={(value) => handleOnChange({
            label: setting.label,
            value
          }, 'Project')} />;
        })}
        <div>
        <button
          className={saveStatus == "Save" ? "bgGreen" : "bgRed disabled"}
          onClick={() => saveProject(updatedSettings.current)}
        >
          {saveStatus}
        </button>
      </div>
      </div>
    );
  };
  const TilesSettings = () => {
    return (
      <div>

        {form.Tiles.map((setting, i) => {
          return <Input key={i} {...setting} onChange={(e) => handleOnChange(e, 'Tiles')} />;
        })}
        <div>
        <button
          className={saveStatus == "Save" ? "bgGreen" : "bgRed disabled"}
          onClick={() => saveProject(updatedSettings.current)}
        >
          {saveStatus}
        </button>
      </div>
      </div>
    );
  };
  const Tabs = ({ tabs, activeTab, onTabChange }) => {
    return (
      <div
        className="tabs"
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          gap: "20px",
        }}
      >
        {tabs.map((tab, i) => {
          return (
            <div
              key={i}
              className={activeTab === i ? "active" : ""}
              onClick={() => onTabChange(i, tab)}
            >
              {tab}
            </div>
          );
        })}
      </div>
    );
  };
  const TabContent = ({ activeTabIndex, settings,selectedBuildingName }) => {
    switch (activeTabIndex) {
      case 0:
        
        return <ProjectSettings />;
      case 1:
        return <BuildingsSettings settings={settings.Buildings} selectedBuildingName={selectedBuildingName}/>;
      case 2:
        return <TilesSettings />;
      case 3:
        return <FloorsSettings />;
      
    
      default:
        return <div>Tab content</div>;
        // break;
    }
  }
  return (
    <div className="p-2">
      <Tabs tabs={tabs} activeTab={activeTabIndex} onTabChange={onTabChange} />
      <TabContent activeTabIndex={activeTabIndex} settings={form} selectedBuildingName={selectedBuildingName}/>
    </div>
  );
};


export default Settings;
