
const initialAzimuth = -90;
const effectController = {
      Initial: {
      name: "Initial",
      // icon: sunIcon,
      turbidity: 0,
      rayleigh: 0,
      mieCoefficient: 0.005,
      mieDirectionalG: 0.7,
      elevation: -5,
      azimuth: initialAzimuth,
      // exposure: renderer.toneMappingExposure,
    },
    Sunset: {
      name: "Sunset",
      // icon: sunIcon,
      turbidity: 2.8,
      rayleigh: 3,
      mieCoefficient: 0.005,
      mieDirectionalG: 0.7,
      elevation: 2,
      azimuth: initialAzimuth,
    //   exposure: renderer.toneMappingExposure,
    },
    Day: {
      name: "Day",
      // icon: sunIcon,
      turbidity: 0.8,
      rayleigh: 0.24,
      mieCoefficient: 0.005,
      mieDirectionalG: 0.7,
      elevation: 75,
      azimuth: initialAzimuth,
    //   exposure: renderer.toneMappingExposure,
    },
    Night: {
      name: "Night",
      // icon: moonIcon,
      turbidity: 0.7,
      rayleigh: 0.029,
      mieCoefficient: 0.005,
      mieDirectionalG: 0.744,
      elevation: 24.1,
      azimuth: initialAzimuth,
    //   exposure: renderer.toneMappingExposure,
    },
    Grid: {
      name: "Grid",
      // icon: moonIcon,
      turbidity: 0.0,
      rayleigh: 0.0,
      mieCoefficient: 0.005,
      mieDirectionalG: 0.744,
      elevation: 0,
      azimuth: initialAzimuth,
    //   exposure: renderer.toneMappingExposure,
    },
  };

    export default effectController;