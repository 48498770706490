import React, { useEffect, useRef, useState } from "react";
import {
  DoubleSide,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  PlaneGeometry,
  RepeatWrapping,
  SphereGeometry,
  TextureLoader,
  Vector3,
  PMREMGenerator,
} from "three";
import effectController from "./effectsController";
import { Sky } from "three/examples/jsm/objects/Sky.js";
import { Water } from "three/examples/jsm/objects/Water.js";
import sunIcon from "../../assets/icons/sun.png";
import moonIcon from "../../assets/icons/moon.png";
import sunsetIcon from "../../assets/icons/sunset.png";
import gridIcon from "../../assets/icons/grid.png";
// import { Sky } from './Sky'
import sky from "../../assets/sky1.jpg";
import night from "../../assets/night.jpg";
import sunset from "../../assets/sunset.jpg";
import waterUrl from "../../assets/waternormals.jpg";
import gui from "../../utils/gui";
import { cameraRenderDistance } from ".";
// import { Water } from "../Water";
import Clouds from "./Clouds";
import Stars from "./Stars";
import gsap from "gsap";
import { createSunPosition } from "../../utils/threeJsUtils";
export const skies = {
  Day: sky,
  Night: night,
  Sunset: sunset,
};
const SkyController = ({ 
  scene, 
  // gridSize, 
  renderer, 
  animationEnded, 
  modelsLoaded,
  currentSkyPreset,
  setcurrentSkyPreset,
  isRtl
 }) => {
  
  const skyDome = useRef();
  const loader = useRef();
  const sun = useRef();
  const sky = useRef();
  const water = useRef();

  // const effectController = {
  //   Initial: {
  //     name: "Initial",
  //     icon: sunIcon,
  //     turbidity: 0,
  //     rayleigh: 0,
  //     mieCoefficient: 0.005,
  //     mieDirectionalG: 0.7,
  //     elevation: -5,
  //     azimuth: initialAzimuth,
  //     exposure: renderer.toneMappingExposure,
  //   },
  //   Sunset: {
  //     name: "Sunset",
  //     icon: sunIcon,
  //     turbidity: 2.8,
  //     rayleigh: 3,
  //     mieCoefficient: 0.005,
  //     mieDirectionalG: 0.7,
  //     elevation: 2,
  //     azimuth: initialAzimuth,
  //     exposure: renderer.toneMappingExposure,
  //   },
  //   Day: {
  //     name: "Day",
  //     icon: sunIcon,
  //     turbidity: 0.8,
  //     rayleigh: 0.24,
  //     mieCoefficient: 0.005,
  //     mieDirectionalG: 0.7,
  //     elevation: 75,
  //     azimuth: initialAzimuth,
  //     exposure: renderer.toneMappingExposure,
  //   },
  //   Night: {
  //     name: "Night",
  //     icon: moonIcon,
  //     turbidity: 0.7,
  //     rayleigh: 0.029,
  //     mieCoefficient: 0.005,
  //     mieDirectionalG: 0.744,
  //     elevation: 24.1,
  //     azimuth: initialAzimuth,
  //     exposure: renderer.toneMappingExposure,
  //   },
  // };
  useEffect(() => {
    if (!sky.current) {
      loader.current = new TextureLoader();
      sun.current = buildSun();
      sky.current = buildSky();
      water.current = buildWater();      

      const pmremGenerator = new PMREMGenerator(renderer);
      scene.environment = pmremGenerator.fromScene(sky.current).texture;
      addGiuControls();
      guiChanged();
      
      // if (!skyDome.current) loadSky(skies.Day)
      // createSky();
      // createGroundPlane();
    }
    return () => {
      if (skyDome.current) {
        scene.remove(skyDome.current);
      }
    };
  }, []);
  useEffect(() => {
    const waterSpeed = 0.1;
    const animate = () => {
      if (water.current) {
        water.current.material.uniforms["time"].value += waterSpeed / 60.0;
      }
      requestAnimationFrame(animate);
    };

    animate();
  }, []);
  useEffect(() => {
    if (modelsLoaded ) {
      if (!animationEnded)
        animateSkyUniforms(effectController["Sunset"], 10);
      else animateSkyUniforms(effectController["Sunset"], 1);
    }   
  
    return () => {
      
    }
  }, [modelsLoaded])
  // sunColor
  // waterColor
  useEffect(() => {
    if (animationEnded) {
      animateSkyUniforms(effectController[currentSkyPreset], 3);
      console.log("water.current", water.current)
      if (currentSkyPreset === 'Grid') {
        //fade out water
        gsap.to(water.current.scale, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        });
      }
      else {
        //fade in water
        gsap.to(water.current.scale, {
          duration: 1,
          x: 1,
          y: 1,
          z: 1,
        });
      }
    }
 
    return () => {
      
    }
  }, [currentSkyPreset])
  
  
  const options = [
    {
      label: "Day",
      //   value: dayImage,
    },
    {
      label: "Night",
      //   value: nightImage,
    },
    {
      label: "Sunset",
      //   value: sunsetImage
    },
  ];
  const animateSkyUniforms = (targetPreset, duration = 3) => {
    // console.log("Animating sky uniforms", targetPreset);
    const uniforms = sky.current.material.uniforms;
  
    const currentValues = {
      turbidity: uniforms["turbidity"].value,
      rayleigh: uniforms["rayleigh"].value,
      mieCoefficient: uniforms["mieCoefficient"].value,
      mieDirectionalG: uniforms["mieDirectionalG"].value,
      ...getElevationAndAzimuth(uniforms["sunPosition"].value)
    };
  
    const endValues = {
      turbidity: targetPreset.turbidity,
      rayleigh: targetPreset.rayleigh,
      mieCoefficient: targetPreset.mieCoefficient,
      mieDirectionalG: targetPreset.mieDirectionalG,
      elevation: targetPreset.elevation,
      azimuth: targetPreset.azimuth,
    };
  
    // Check if the target preset is the same as the current values
    if (JSON.stringify(currentValues) === JSON.stringify(endValues)) {
      console.log("Target preset is the same as current values. No animation needed.");
      return;
    }
  
    gsap.to(currentValues, {
      duration: duration,
      turbidity: endValues.turbidity,
      rayleigh: endValues.rayleigh,
      mieCoefficient: endValues.mieCoefficient,
      mieDirectionalG: endValues.mieDirectionalG,
      elevation: endValues.elevation,
      azimuth: endValues.azimuth,
      onUpdate: () => {
        uniforms["turbidity"].value = currentValues.turbidity;
        uniforms["rayleigh"].value = currentValues.rayleigh;
        uniforms["mieCoefficient"].value = currentValues.mieCoefficient;
        uniforms["mieDirectionalG"].value = currentValues.mieDirectionalG;
  
        const sunPosition = createSunPosition(currentValues.elevation, currentValues.azimuth);
        uniforms["sunPosition"].value.copy(sunPosition);
      },
      onComplete: () => {
        // Ensure the uniforms match the end values when the animation completes
        uniforms["turbidity"].value = endValues.turbidity;
        uniforms["rayleigh"].value = endValues.rayleigh;
        uniforms["mieCoefficient"].value = endValues.mieCoefficient;
        uniforms["mieDirectionalG"].value = endValues.mieDirectionalG;
  
        const sunPosition = createSunPosition(endValues.elevation, endValues.azimuth);
        uniforms["sunPosition"].value.copy(sunPosition);
      }
    });
  };
  
  const addGiuControls = () => {
    gui.get((gui) => {
      gui
        .add(effectController[currentSkyPreset], "turbidity", 0.0, 20.0, 0.1)
        .onChange(guiChanged);
      gui
        .add(effectController[currentSkyPreset], "rayleigh", 0.0, 4, 0.001)
        .onChange(guiChanged);
      gui
        .add(
          effectController[currentSkyPreset],
          "mieCoefficient",
          0.0,
          0.1,
          0.001
        )
        .onChange(guiChanged);
      gui
        .add(
          effectController[currentSkyPreset],
          "mieDirectionalG",
          0.0,
          1,
          0.001
        )
        .onChange(guiChanged);
      gui
        .add(effectController[currentSkyPreset], "elevation", -360, 360, 0.01)
        .onChange(guiChanged);
      gui
        .add(effectController[currentSkyPreset], "azimuth", -180, 180, 0.1)
        .onChange(guiChanged);
      // gui.add(effectController, "exposure", 0, 1, 0.0001).onChange(guiChanged);

      guiChanged();
    });
  };
// Converts elevation and azimuth angles to Cartesian coordinates (x, y, z)


// Converts Cartesian coordinates (x, y, z) to elevation and azimuth angles
function getElevationAndAzimuth(sunPosition) {
  const { x, y, z } = sunPosition;

  const r = Math.sqrt(x * x + y * y + z * z); // Radius
  const phi = Math.acos(y / r); // Polar angle in radians
  const theta = Math.atan2(z, x); // Azimuthal angle in radians

  const elevation = 90 - (phi * (180 / Math.PI)); // Convert to degrees
  const azimuth = theta * (180 / Math.PI); // Convert to degrees

  return { elevation, azimuth };
}


  function guiChanged() {
    if (!sky.current) return;
    console.log("guiChanged", effectController[currentSkyPreset]);
    const uniforms = sky.current.material.uniforms;
    uniforms["turbidity"].value = effectController[currentSkyPreset].turbidity;
    uniforms["rayleigh"].value = effectController[currentSkyPreset].rayleigh;
    uniforms["mieCoefficient"].value =
      effectController[currentSkyPreset].mieCoefficient;
    uniforms["mieDirectionalG"].value =
      effectController[currentSkyPreset].mieDirectionalG;

    const sunPosition = createSunPosition(effectController[currentSkyPreset].elevation, effectController[currentSkyPreset].azimuth);

    uniforms["sunPosition"].value.copy(sunPosition);

    renderer.toneMappingExposure = effectController[currentSkyPreset].exposure;
  }
  function buildSky() {
    const sky = new Sky();
    sky.scale.setScalar(10000);   
    // sky.current.material.uniforms["sunPosition"].value.copy(sun.current);

    scene.add(sky);
    return sky;
  }
  function buildSun() {
    
    const sun = createSunPosition(effectController[currentSkyPreset].elevation, effectController[currentSkyPreset].azimuth);;

    // Defining the x, y and z value for our 3D Vector
    // const theta = Math.PI * (0.49 - 0.5);
    // const phi = 2 * Math.PI * (0.205 - 0.5);
    // sun.x = Math.cos(phi);
    // sun.y = Math.sin(phi) * Math.sin(theta);
    // sun.z = Math.sin(phi) * Math.cos(theta);    
    
    return sun;
  }
  function buildWater() {
    const waterGeometry = new PlaneGeometry(10000, 10000);
    const water = new Water(waterGeometry, {
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: loader.current.load(waterUrl, function (texture) {
        texture.wrapS = texture.wrapT = RepeatWrapping;
      }),
      alpha: 1.0,
      sunDirection: new Vector3(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 3.7,
      fog: scene.fog !== undefined,
    });
    water.rotation.x = -Math.PI / 2;
    // water.position.y = -100;
    scene.add(water);

    return water;
  }
  // const createSky = () => {
  //   sky.current = new Sky();
  //   sky.current.scale.setScalar(450000);
  //   console.log("sky.current", sky.current);
  //   const phi = MathUtils.degToRad(90);
  //   const theta = MathUtils.degToRad(0);
  //   const sunPosition = new Vector3().setFromSphericalCoords(1, phi, theta);
  //   sky.current.material.uniforms.sunPosition.value = sunPosition;
  //   sky.current.material.uniforms.exposure = 0.0;

  //   gui.get((gui) => {
  //     gui
  //       .add(effectController[currentSkyPreset], "turbidity", 0.0, 20.0, 0.1)
  //       .onChange(guiChanged);
  //     gui
  //       .add(effectController[currentSkyPreset], "rayleigh", 0.0, 4, 0.001)
  //       .onChange(guiChanged);
  //     gui
  //       .add(
  //         effectController[currentSkyPreset],
  //         "mieCoefficient",
  //         0.0,
  //         0.1,
  //         0.001
  //       )
  //       .onChange(guiChanged);
  //     gui
  //       .add(
  //         effectController[currentSkyPreset],
  //         "mieDirectionalG",
  //         0.0,
  //         1,
  //         0.001
  //       )
  //       .onChange(guiChanged);
  //     gui
  //       .add(effectController[currentSkyPreset], "elevation", 0, 360, 0.1)
  //       .onChange(guiChanged);
  //     gui
  //       .add(effectController[currentSkyPreset], "azimuth", -180, 180, 0.1)
  //       .onChange(guiChanged);
  //     // gui.add(effectController, "exposure", 0, 1, 0.0001).onChange(guiChanged);

  //     guiChanged();
  //   });
  //   scene.add(sky.current);
  // };
  // const createGroundPlane = () => {
  //   const floorGeometry = new PlaneGeometry(100000, 100000); // Large plane to extend to the horizon
  //   const floorMaterial = new MeshStandardMaterial({
  //     color: "black",
  //     metalness: 0.2,
  //     roughness: 0.4,
  //     envMapIntensity: 0.5,
  //     side: DoubleSide,
  //   });
  //   const floor = new Mesh(floorGeometry, floorMaterial);
  //   floor.rotation.x = -Math.PI / 2;
  //   floor.position.y = 1;
  //   scene.add(floor);
  // };
  // const loadSky = async (image) => {
  //   // console.log("Loding sky", image);

  //   loader.current.load(image, async function (texture) {
  //     // console.log("texture", texture);
  //     // Create a sphere geometry
  //     var geometry = new SphereGeometry(
  //       gridSize / 2,
  //       60,
  //       40,
  //       0,
  //       Math.PI * 2,
  //       0,
  //       Math.PI / 2
  //     );

  //     // Flip the geometry so that the textured side faces inwards
  //     geometry.scale(-1, 1, 1);

  //     // Apply the texture to the sphere material
  //     var material = new MeshBasicMaterial({
  //       map: texture,
  //       transparent: true,
  //     });

  //     // Create a new mesh with the sphere geometry and the material, and add it to the scene
  //     var newSkyDome = new Mesh(geometry, material);
  //     // console.log("skyDome.current", skyDome.current);
  //     if (skyDome.current) {
  //       await fadeOutSkyDome(skyDome.current);
  //       await fadeInSkyDome(newSkyDome);
  //     } else await fadeInSkyDome(newSkyDome);
  //     // if (skyDome.current) {
  //     //   scene.remove(skyDome.current);
  //     // }

  //     // scene.add(skyDome.current);
  //   });
  // };
  // const fadeOutSkyDome = (skyDome, duration = 500) => {
  //   // console.log("Fading out skydome", skyDome);
  //   return new Promise((resolve) => {
  //     const start = performance.now();
  //     const fade = () => {
  //       const elapsed = performance.now() - start;
  //       const progress = Math.min(elapsed / duration, 1);
  //       skyDome.material.opacity = 1 - progress;
  //       if (progress < 1) {
  //         requestAnimationFrame(fade);
  //       } else {
  //         scene.remove(skyDome);
  //         resolve();
  //       }
  //     };
  //     fade();
  //   });
  // };

  // const fadeInSkyDome = (skyDome, duration = 500) => {
  //   console.log("Fading in skydome", skyDome);
  //   return new Promise((resolve) => {
  //     skyDome.material.opacity = 0;
  //     scene.add(skyDome);
  //     skyDome.current = skyDome;
  //     const start = performance.now();
  //     const fade = () => {
  //       const elapsed = performance.now() - start;
  //       const progress = Math.min(elapsed / duration, 1);
  //       skyDome.material.opacity = progress;
  //       if (progress < 1) {
  //         requestAnimationFrame(fade);
  //       } else {
  //         resolve();
  //       }
  //     };
  //     fade();
  //   });
  // };
  const setEnviroment = async (envName) => {
    console.log("envName", envName);
    animateSkyUniforms(effectController[envName]);
    setcurrentSkyPreset(envName);
  };
  const ButtonWithIcon = ({ icon, text, onClick }) => {
    return (
      <button onClick={onClick} style={{
        background: 'none',
        border: 'none',
      }}>
        <img src={icon} alt={text} style={{}} />
        {/* <div 
          className="text"
        style={{
          color: 'white',
        }}>{text}</div> */}
      </button>
    );
  };
  const buttons = [
    {
      name: "Day",
      icon: sunIcon,
    },
    {
      name: "Night",
      icon: moonIcon,
    },
    {
      name: "Sunset",
      icon: sunsetIcon,
    },
    {
      name: "Grid",
      icon: gridIcon,
    },
  ]
  let style = {
    position: "fixed",
    bottom: "2rem",
    left: animationEnded ? "2rem" : "-100rem",
    right: "auto",
    zIndex: 10,
    width: "15rem",
    height: "4rem",
    backgroundColor: 'rgba(28, 28, 28, .6)',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "1rem",
    transition: "all 0.5s",
  }
  if (!isRtl) {
    style = {
      ...style,
      left: "auto",      
      right: animationEnded ? "2rem" : "-100rem",
    }
  }
  return  (
    <div
      className="enviroment"
      style={style}
    >
      {buttons.map((option, i) => {
        return (
          <ButtonWithIcon key={i} icon={option.icon} text={option.name} onClick={() => setEnviroment(option.name)} />
        );
      })}
      {/* <Clouds scene={scene} /> */}

      <Stars scene={scene} show={currentSkyPreset !== 'Grid'}/>
    </div>
  );
};

export default SkyController;
