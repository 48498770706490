import React, { useEffect, useRef } from "react";
import { BackSide, Color, SphereGeometry, ShaderMaterial, Mesh } from "three";
import vertexShader from "../../../shaders/default.vert";
// import fragment from "../../../shaders/environment.frag";
import { useRawData } from "../../../hooks";
// console.log(vertexShader);
// const vertexShader = `
// varying vec2 vUv;

// void main() {

// 	vUv = uv;
//   gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
// }`;

const fragmentShader = `
uniform vec3 uTopColor;
uniform vec3 uBottomColor;
uniform vec3 uSpot1Color;
uniform vec3 uSpot2Color;
uniform vec2 uSpot1Position;
uniform vec2 uSpot2Position;

varying vec2 vUv;

float distanceFromPoint(vec2 uv, vec2 point, float max){
  float d = distance(uv, point);
  d = smoothstep(0.0, max, d);
  d = 1.0 - d;
  return d;
}

void main() {

  float d1 = distanceFromPoint(vUv, vec2(uSpot1Position), 0.3);
  float d2 = distanceFromPoint(vUv, vec2(uSpot2Position), 0.4);

  vec4 colorSpot1 = vec4(uSpot1Color, 1.0 * d1 * 0.8);
  vec4 colorSpot2 = vec4(uSpot2Color, 1.0  * d2 * 0.8);
  vec4 verticalGradient = vec4(mix(uBottomColor,uTopColor, vUv.y), 1.0);
  vec4 mixVS1 = mix(verticalGradient, colorSpot1, colorSpot1.a);
  vec4 final = mix(mixVS1, colorSpot2, colorSpot2.a);

	gl_FragColor = vec4(final.rgb, 1.0);
}

`;
const Environment = ({ scene }) => {
  const meshRef = useRef();
  const radius = 300;
  const colorSteps = useRawData("colors.gradients");

  useEffect(() => {
    const uniforms = {
      uTopColor: { value: new Color(colorSteps[0].top) },
      uBottomColor: { value: new Color(colorSteps[0].bottom) },
      uSpot1Color: { value: new Color(colorSteps[0].spot1) },
      uSpot1Position: { value: [0.4, 0.7] },
      uSpot2Color: { value: new Color(colorSteps[0].spot2) },
      uSpot2Position: { value: [0.6, 0.4] },
    };

    const geometry = new SphereGeometry(radius, 30, 30);
    const material = new ShaderMaterial({
      uniforms: uniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: BackSide,
    });

    const mesh = new Mesh(geometry, material);
    mesh.rotation.set(0, 0, 0.12);
    scene.add(mesh);
    meshRef.current = mesh;

    return () => {
      //   scene.remove(mesh);
    };
  }, [colorSteps, scene]);

  return null;
};

export default Environment;
