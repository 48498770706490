import React from "react";
import Dot from "./Dot";

const HoverApp = ({
  apartment,
  mousePosition,
  goToApartmentPage,
  isRtl,
  colors,
  statuses,
  apartmentBlockFieldsOrder,
}) => {
  // console.log(mousePosition.y)
  // console.log(window.innerHeight / 2)
  const width = 250;
  const height = 300;
  const status =
    apartment && statuses.find((sts) => sts.status_name == apartment.available);
  let style = {
    position: "absolute",
    // width: width,
    // minHeight: height,
    direction: isRtl ? "rtl" : "ltr",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  };
  if (apartment) {
    if (mousePosition.y < window.innerHeight / 2) {
      style.top = mousePosition.y;
    } else {
      style.bottom = window.innerHeight - mousePosition.y;
    }
    if (!isRtl) {
      style.left = mousePosition.x + 30;
    } else {
      style.left = mousePosition.x - width - 30;
    }
  }

  const Content = () => {
    return (
      <>
        <div
          className="header"
          style={{
            display: "flex",
            alignItems: "start",
          }}
        >
          {status && (
            <Dot
              cx={3}
              cy={3}
              radius={3}
              fill={status.status_color}
              style={{
                width: ".5rem",
                height: ".5rem",
                marginTop: "5px",
              }}
            />
          )}
          <span
            style={{
              marginRight: ".5rem",
              marginLeft: ".5rem",
            }}
          >
            {/* {isRtl ? "קומה" : "Floor"} {apartment.floor_name} */}
            {status.status_name}
          </span>
        </div>
        {/* {(apartment.type_thumbnail && apartment.type_thumbnail.length) && <div className="apt-thumbnail" style ={{
            width: '100%',
            overflow: 'hidden',
        }}>
          <img
            src={apartment.type_thumbnail}
            alt=""
            style={{       
              height: '10rem',
              maxHeight: '10rem',
              width: 'auto',
              maxWidth: 'none',
              overflow: 'hidden'
            }}
          />

        </div>} */}
        <div
          className="mt-1"
          style={{
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="detailsList">
            <ul>
              {apartmentBlockFieldsOrder.map((detail) => (
                <li key={detail.title}>
                  <div className="d-flex mb-1">
                    <span>
                      {detail.title}{" "}
                      {
                        apartment.display_fields.find(
                          (field) => field.field == detail.field
                        )?.value
                      }
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="">
            <div className="floor"
              style={{
                position: 'absolute',
                top: '5px',
                left: !isRtl ? 'auto ': '1.5rem',
                right: !isRtl ? '1.5rem': 'auto',
              }}>
              <span
                style={{
                  marginRight: ".5rem",
                  marginLeft: ".5rem",
                }}
              >
                {isRtl ? "קומה" : "Floor"}
              </span>
              <div
                className=""
                style={{
                  // display: "flex",
                  // alignItems: "center",
                  fontSize: 36,
                }}
              >
                {apartment.floor_name}
              </div>

            </div>
            <div
              className="roundButton"
              onClick={() => goToApartmentPage(apartment)}
              style={{
                border: `1px solid ${colors.primary}`,
              }}
            >
              <span>{isRtl ? "סיור" : "Tour"}</span>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div
      className={`apartmentBox rounded shadowed ${apartment && "active"}`}
      style={style}
    >
      {apartment && <Content />}
    </div>
  );
};

export default HoverApp;
